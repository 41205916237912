<template>
  <div class="row csn-user-account-section csn-transaction" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <p>{{ t('description') }}</p>
        </section>

        <section>
          <h2>{{ t('filter_transactions') }}</h2>
          <div>
            <Message
              :type="messageTypeEnum.FAILURE"
              :message="message"
              :hasBottom="true"
            />
            <Form class="form-inline" :ref="formRef">
              <div class="row-fluid">
                <DateField
                  :label="t('date_from')"
                  :name="name.DATE_FROM"
                  v-model="formData[name.DATE_FROM]"
                  :validators="[validator.required]"
                  :serverError="serverError[name.DATE_FROM]"
                />
                <DateField
                  :label="t('date_to')"
                  :name="name.DATE_TO"
                  v-model="formData[name.DATE_TO]"
                  :validators="[validator.required]"
                  :serverError="serverError[name.DATE_TO]"
                />
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn casino-btn-ver-1 casino-btn csn-transaction-submit-btn casino-btn-theme-based"
                    :disabled="isSubmitting"
                    @click="submitForm"
                  >
                    <ButtonLoader v-if="isSubmitting" />
                    <span v-else>{{ t('bttn_label') }}</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </section>

        <section v-if="isSubmitting">
          <Loader />
        </section>

        <section v-if="rendersTable" class="csn-transaction-table-section">
          <h2>{{ t('showing_results') }}</h2>
          <div
            v-if="isMobileScreen"
            class="csn-transaction-mobile-table-container"
          >
            <table
              v-for="({
                createdAt,
                transactionType,
                currency,
                bonusAmount,
                bonusBalance,
                amount,
                balance,
              },
              id) in list"
              :key="id"
              class="csn-transaction-mobile-table"
            >
              <tr>
                <th>{{ t('date') }}</th>
                <td>{{ formatCreateDate(createdAt) }}</td>
              </tr>
              <tr>
                <th>{{ t('method') }}</th>
                <td>{{ transactionType }}</td>
              </tr>
              <tr>
                <th class="csn-transaction-amount">{{ t('bonus_amount') }}</th>
                <td class="csn-transaction-amount">
                  {{ format({ number: bonusAmount, currency }) }}
                </td>
              </tr>
              <tr>
                <th class="csn-transaction-amount">{{ t('bonus_balance') }}</th>
                <td class="csn-transaction-amount">
                  {{ format({ number: bonusBalance, currency }) }}
                </td>
              </tr>
              <tr>
                <th class="csn-transaction-amount">{{ t('amount') }}</th>
                <td class="csn-transaction-amount">
                  {{ format({ number: amount, currency }) }}
                </td>
              </tr>
              <tr>
                <th class="csn-transaction-amount">{{ t('balance') }}</th>
                <td class="csn-transaction-amount">
                  {{ format({ number: balance, currency }) }}
                </td>
              </tr>
            </table>
            <div class="csn-transaction-paginator-container">
              <Paginator v-model="pagination" @getData="getTransactions" />
            </div>
          </div>

          <div v-else>
            <table class="csn-transaction-table">
              <tr>
                <th>{{ t('date') }}</th>
                <th>{{ t('method') }}</th>
                <!-- <th>{{ t('status') }}</th> -->
                <!-- <th>{{ t('currency') }}</th> -->
                <th class="csn-transaction-amount">{{ t('bonus_amount') }}</th>
                <th class="csn-transaction-amount">{{ t('bonus_balance') }}</th>
                <th class="csn-transaction-amount">{{ t('amount') }}</th>
                <th class="csn-transaction-amount">{{ t('balance') }}</th>
              </tr>

              <tr
                v-for="({
                  createdAt,
                  transactionType,
                  currency,
                  bonusAmount,
                  bonusBalance,
                  amount,
                  balance,
                },
                id) in list"
                :key="id"
              >
                <td>{{ formatCreateDate(createdAt) }}</td>
                <td>{{ transactionType }}</td>
                <!-- <td>WIP: status</td> -->
                <!-- <td>{{ transaction.currency }}</td> -->
                <td class="csn-transaction-amount">
                  {{ format({ number: bonusAmount, currency }) }}
                </td>
                <td class="csn-transaction-amount">
                  {{ format({ number: bonusBalance, currency }) }}
                </td>
                <td class="csn-transaction-amount">
                  {{ format({ number: amount, currency }) }}
                </td>
                <td class="csn-transaction-amount">
                  {{ format({ number: balance, currency }) }}
                </td>
              </tr>
            </table>
            <div class="csn-transaction-paginator-container">
              <Paginator v-model="pagination" @getData="getTransactions" />
            </div>
          </div>
        </section>

        <section v-if="isEmptyList">
          <p class="csn-user-date-empty-list">{{ t('no_transactions') }}</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_TRANSACTIONS, Module, IS_MOBILE_SCREEN } from '@/constants'
import { formatNumberLocale as format, formatDate, dest } from '@/helpers'
import { periodFormMixin } from '@/mixins'
import { AuthApi } from '@/api'

export default {
  name: USER_TRANSACTIONS,
  mixins: [periodFormMixin],
  components: {
    Loader: () => import('@/components/Loader'),
    Paginator: () => import('@/components/Paginator'),
    Form: () => import('@/components/FormData'),
    DateField: () => import('@/components/DateField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
  },
  computed: {
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    t() {
      return this.$createComponentTranslator(USER_TRANSACTIONS)
    },
  },
  methods: {
    format,
    getTransactions({ ...args }) {
      this.getData({ action: AuthApi.getTransactions, args })
    },
    submitForm() {
      this.$refs[this.formRef].getValidation().isValid && this.getTransactions()
    },
    formatCreateDate: (date) => formatDate({ date }),
  },
  mounted() {
    this.getTransactions()
  },
}
</script>
